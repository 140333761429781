<template>
    <div class="block-custom">
        <div class="preview">
            <div class="block_type_name">
                {{ content.type.name }}
            </div>
        </div>
        <div class="edit-info-wrap">
            <div class="edit-info">{{ $t('controls.for_update_contact_support') }}</div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "BlockCustom",
    props: {
        content: {
            type: Object,
            required: true
        },
    },
}
</script>

<style scoped>
.block-custom{
    position: relative;
    padding:0.5rem;
    min-height: 10rem;
}
.preview,.edit-info-wrap{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.preview{
    /* max-width: 30rem;
    margin: auto; */
    transition: filter var(--transition-ease),var(--transition-opacity);
    filter: blur(0.1px);
    font-size: 1.4rem;
    background-color: #e9fff3;
    /* opacity: 0.5; */
    background: repeating-linear-gradient( -45deg, #177542, #177542 3.5px, #e9fff3 3.5px, #e9fff3 17.5px );
}
.block_type_name{
    background-color: #e9fff3;
    box-shadow: 0 0 15px 20px #e9fff3;
}
.edit-info-wrap{
    opacity: 0;
    font-size: 1.5rem;
    background: rgb(179 253 212 / 33%);
    transition: var(--transition-opacity);
}
.block-custom:hover .edit-info-wrap{
    opacity: 1;
}
.block-custom:hover .preview{
    filter: blur(5px);
    opacity: 0.5;
}
</style>